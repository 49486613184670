/* .allApp {
  margin-bottom: 70px;
  min-height: 100vh;
} */

/* html,
body,
#root,
#app {
  height: 100vh;
} */

body {
  min-width: 1024px;
}

#app {
  position: relative;
  padding-top: 50px;
  margin-bottom: 70px;
}

.block {
  display: inline-block;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
}

.grid {
  background-color: rgb(206, 206, 206);
  width: 490px;
  line-height: 0;
  margin: auto;
  box-shadow: 0px 0px 20px rgb(53, 53, 53);
  margin-top: 50px;
  margin-bottom: 50px;
}

.about {
  position: relative;
  margin: auto;
}

#winButton {
  font-weight: bold;
  letter-spacing: 10px;
  margin-top: 20px;
}

.winContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 45%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
}

.resetButton {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.exitButton {
  position: absolute;
  left: 75%;
  margin: 0px;
}

.blur {
  filter: blur(3px);
  opacity: 0.6;
}

.center {
  margin: auto;
  display: table;
  margin-top: 10px;
}

.palette {
  box-shadow: 0px 0px 10px rgb(53, 53, 53);
  cursor: pointer;
  margin-top: 50px;
}

.floorBlock {
  background-color: rgb(206, 206, 206);
  /* transition: background-color 200ms ease-out;
  animation: subtleBurst 0.2s;
  animation-iteration-count: 1; */
}

.wallBlock {
  background-color: rgb(180, 175, 175);
}

.bombBlock {
  background-image: url("./sprites/bombf1.png");
  background-position: center center;
  background-size: 100% 100%;
  animation: bombFrames 0.8s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  /* background-color: rgb(109, 109, 122); */
}

.blueBlock {
  background-image: url("./sprites/blue.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(81, 81, 243); */
}

.yellowBlock {
  background-image: url("./sprites/yellow.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(236, 236, 53); */
}
.redBlock {
  background-image: url("./sprites/red.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(223, 85, 85); */
}

.greenBlock {
  background-image: url("./sprites/green.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(100, 192, 100);
  border: solid 4px;
  border-color: rgb(98, 168, 98); */
}

.purpleBlock {
  background-image: url("./sprites/purple.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(170, 101, 170);
  border: solid 4px;
  border-color: rgb(161, 89, 161); */
}

.orangeBlock {
  background-image: url("./sprites/orange.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(243, 173, 42);
  border: solid 4px;
  border-color: rgb(218, 162, 58); */
}

.combineGreenBlock {
  background-image: url("./sprites/green.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(100, 192, 100);
  border: solid 4px;
  border-color: rgb(98, 168, 98); */
  animation: burst 0.3s;
  animation-iteration-count: 1;
}

.combinePurpleBlock {
  background-image: url("./sprites/purple.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(170, 101, 170);
  border: solid 4px;
  border-color: rgb(161, 89, 161); */
  animation: burst 0.3s;
  animation-iteration-count: 1;
}

.combineOrangeBlock {
  background-image: url("./sprites/orange.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(243, 173, 42);
  border: solid 4px;
  border-color: rgb(218, 162, 58); */
  animation: burst 0.3s;
  animation-iteration-count: 1;
}

.playerBlock {
  background-image: url("./sprites/hmframe1.png");
  background-position: center center;
  background-size: 100% 100%;
  animation: playerFrames 0.8s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  /* background-color: rgb(250, 242, 242);
  border: solid 4px;
  border-color: rgb(235, 221, 221); */
}

.brownBlock {
  background-image: url("./sprites/brown.png");
  background-position: center center;
  background-size: 100% 100%;
  /* background-color: rgb(165, 129, 62);
  border: solid 2px;
  border-color: rgb(124, 97, 71); */
}

.flashBlock {
  background-color: rgb(206, 206, 206);
  animation: subtleBurst 0.3s;
  animation-iteration-count: 1;
}

.explodeBlock {
  background-color: rgb(206, 206, 206);
  animation: explode 0.15s;
  animation-iteration-count: 2;
}

@keyframes playerFrames {
  0% {
    background-image: url("./sprites/hmframe1.png");
  }

  33% {
    background-image: url("./sprites/hmframe2.png");
  }

  66% {
    background-image: url("./sprites/hmframe3.png");
  }
  100% {
    background-image: url("./sprites/hmframe1.png");
  }
}

@keyframes bombFrames {
  0% {
    background-image: url("./sprites/bombf1.png");
  }

  33% {
    background-image: url("./sprites/bombf2.png");
  }

  66% {
    background-image: url("./sprites/bombf3.png");
  }
  100% {
    background-image: url("./sprites/bombf1.png");
  }
}

@keyframes burst {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1.3);
  }
}

@keyframes subtleBurst {
  0% {
    background-color: white;
    transform: scale(1);
  }

  100% {
    opacity: 0.8;
    transform: scale(1.3);
  }
}

@keyframes explode {
  0% {
    background-color: rgb(165, 129, 62);
    opacity: 1;
    transform: scale(1);
  }

  100% {
    background-color: rgb(255, 255, 255);
    opacity: 0.5;
    transform: scale(1.7);
  }
}

.selectedBlock {
  animation: selectedScale 0.5s;
  animation-fill-mode: forwards;
}

@keyframes selectedScale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.35);
    /* outline: solid;
    outline-width: 2px;
    outline-color: rgb(49, 47, 47); */
  }
}

h1,
h2,
h3 {
  color: rgb(172, 163, 163);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

h4 {
  color: rgb(172, 163, 163);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

h5 {
  color: rgb(172, 163, 163);
  margin-top: 10px;
  margin-bottom: 30px;
}

.board {
  margin-top: 60px;
}

.editor {
  margin-top: 30px;
}

button {
  align-self: center;
  margin-top: 30px;
}

.logInForm {
  margin-top: 50px;
}

.filterLevels {
  position: absolute;
  right: 20%;
}

.toggleLevels {
  position: absolute;
  left: 20%;
}

.signUpButton {
  align-self: center;
  text-align: center;
  margin: auto;
}

.levelPreviewContainer {
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-right: 14px;
  position: relative;
  background-color: rgb(206, 206, 206);
  box-shadow: 0px 0px 3px rgb(126, 125, 125);
}

.deleteButton {
  position: relative;
}

.statusImage {
  filter: opacity(50%);
  /* box-shadow: 0px 0px 3px rgb(255, 255, 255); */
}

#submitButton {
  width: "fluid";
  margin: 15px;
}

.loginButtons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

#navBar {
  /* margin-top: 15px; */
  position: fixed;
  top: 0%;
  z-index: 1001;
  background-color: white;
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 48px;
  width: 300px;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .toggleLevels {
    display: none !important;
  }
  .filterLevels {
    position: relative;
    margin-bottom: 10px !important;
    left: 10%;
  }
  .hueLogo {
    display: none !important;
  }
  .huePhoneLogo {
    display: block !important;
  }
  .hideWidth {
    display: none !important;
  }
  .footer {
    display: none !important;
  }
  .exitButton {
    position: relative !important;
    left: 80%;
  }
  .board {
    margin-top: 0px;
  }
}
